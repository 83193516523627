:root {
	/* ------- colors ------- */
	--primary-color: #182519;
	--secondary-color: #656d6a;
	--tertiary-color: #acb4ad;
	--quaternary-color: #e4e4e7;
	--link-color: #136158;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
